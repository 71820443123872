import { ofetch, type FetchOptions } from "ofetch";
import { canUseDOM } from "~/lib/dom";
import type { WalletState } from "~/stores/wallets";
import { isProduction } from "./env";

export interface IResponseMap {
  blob: Blob;
  text: string;
  arrayBuffer: ArrayBuffer;
  stream: ReadableStream<Uint8Array>;
}

export type ResponseType = keyof IResponseMap | "json";
export type MappedType<
  R extends ResponseType,
  JsonType = any
> = R extends keyof IResponseMap ? IResponseMap[R] : JsonType;

export type RequestInfo = globalThis.RequestInfo;
export type FetchRequest = RequestInfo;

export type APIConfig = {
  baseURL?: string;
  headers?: Record<string, string>;
};

export const config: APIConfig = {
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    Accept: "application/json",
  },
};

export function updateConfig(options: APIConfig) {
  Object.assign(config, options);
}

const PRODUCTION = isProduction();
console.log("APP_PRODUCTION ====>", PRODUCTION);

updateConfig({
  baseURL: PRODUCTION
    ? "https://soga-node-api.sonic.game"
    : "https://soga-node-staging-api.sonic.game",
  headers: {
    "x-api-key": PRODUCTION
      ? "mw_BMAJBbq64GvSFmI1VWOcmiHVokv4djsS2PF"
      : "mw_testNPGAVzMoUien219Q7Vksx3lrRGydITIQ8pX",
  },
});

export const $fetch = ofetch.create({
  baseURL: config.baseURL,
  headers: config.headers,
  onRequest(context) {
    context.options.baseURL = config.baseURL;
    context.options.headers = {
      ...context.options.headers,
      ...config.headers,
    };
    if (canUseDOM()) {
      const wallet = localStorage.getItem("wallet");
      if (wallet) {
        let state: WalletState;
        try {
          state = JSON.parse(wallet) as WalletState;
          if (state.identityToken) {
            context.options.headers = {
              ...context.options.headers,
              Authorization: `Bearer ${state.identityToken}`,
            };
            context.options.credentials = "include";
          }
        } catch (error) {}
      }
    }
  },
  credentials: "include",
});

/**
 * HTTP API Fetch instance
 * @param request
 * @param options
 * @returns
 */
export function __api<T = any, R extends ResponseType = "json">(
  request: FetchRequest,
  options?: FetchOptions<R>
): Promise<MappedType<R, T>> {
  return $fetch<T, R>(request, options) as Promise<MappedType<R, T>>;
}

export type Method = "get" | "post" | "put" | "patch" | "delete";

export type APIMethodHandlers = {
  [M in Method]: <T = any, R extends ResponseType = "json">(
    url: string,
    options?: Omit<FetchOptions, "method">
  ) => Promise<MappedType<R, T>>;
} & typeof __api;

// HTTP client factory
export function clientFactory() {
  const cache = new Map();

  const _api = new Proxy(__api, {
    apply(target, thisArg, args: Parameters<typeof __api>) {
      return __api(...args);
    },
    get(_, method) {
      const targetMethod = method as unknown as Method;
      if (!cache.has(targetMethod)) {
        cache.set(targetMethod, function api<
          T = any,
          R extends ResponseType = "json"
        >(request: FetchRequest, options?: FetchOptions<R>): Promise<
          MappedType<R, T>
        > {
          return $fetch<T, R>(request, {
            ...options,
            method: targetMethod.toUpperCase(),
          }) as Promise<MappedType<R, T>>;
        });
      }
      return cache.get(targetMethod);
    },
  }) as unknown as APIMethodHandlers;

  return _api;
}

export const api = clientFactory();
